<template>
  <b-card no-body class="p-1">
    <b-overlay
      :show="state.loading"
      rounded="sm"
    >
      <div class="demo-spacing-0 m-1" dir="rtl">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
          فیلتر
          <feather-icon icon="FilterIcon"/>
        </b-button>
        <b-form-checkbox
          switch
          inline
          v-tooltip="BUY?'معاملات خرید':'معاملات فروش'"
          class="ml-3 align-middle"
          style="min-height: 24px"
          v-model="BUY"
          @change="getData(1,perPage)"
        >{{ BUY ? 'معاملات خرید' : 'معاملات فروش' }}
        </b-form-checkbox>
      </div>

      <search-and-filter
        :is-active.sync="isActive"
        :options="columns"
        @filter="getData(1, perPage)"
        @reset="getData(1, perPage)"
      />

      <!--        <history-details-->
      <!--                :is-active.sync="isDetails"-->
      <!--                :order="items[1]"-->
      <!--        />-->

      <div class="relative-over-x">
        <b-table
          ref="refUserListTable"
          :items="items"
          :small="true"
          responsive
          :fields="columns"
          primary-key="id"
          show-empty
          empty-text="اطلاعاتی یافت نشد"
          @sort-changed="sort($event)"
          no-local-sort
          style="white-space: nowrap; min-height : 235px"
        >

          <template #cell(market)="data">
            <b-avatar
              size="22"
              class="my-50"
              :src="data.item.order.market.coin.logo"
              v-tooltip="data.item.order.market.coin.label"
              :variant="`light-info`"
            />
            /
            <b-avatar
              size="22"
              class="my-50"
              :src="data.item.order.market.quote.logo"
              v-tooltip="data.item.order.market.quote.label"
              :variant="`light-info`"
            />
          </template>

          <template #cell(amount)="data">
                    <span :dir="BUY ? 'ltr' : 'rtl'">
                        {{
                        $toLocal(data.item.amount)
                      }} {{
                        data.item.type === 'BUY' ? data.item.order.market.coin.label : data.item.order.market.quote.label
                      }}
                    </span>
          </template>

          <template #cell(email)="data">
                        <span dir="rtl">
                            {{ data.item.order.user.email }}
                        </span>
          </template>

          <template #cell(price)="data">
                        <span dir="rtl">
                            {{ $toLocal(data.item.price) }}
                        </span>
          </template>

          <template #cell(total)="data">
                        <span dir="rtl">
                            {{ $toLocal(data.item.total) }}
                        </span>
          </template>

          <template #cell(priceToman)="data">
                        <span dir="rtl">
                            {{ $toLocal(data.item.price_toman) }}
                        </span>
          </template>

          <template #cell(totalToman)="data">
                        <span dir="rtl">
                            {{ $toLocal(data.item.total_toman) }}
                        </span>
          </template>

          <template #cell(type)="{item}">
            <b-badge
              pill
              :variant="'light-'+typeVariant(item.type)"
              class="text-capitalize"
            >
              {{ item.type }}
            </b-badge>
          </template>

          <template #cell(method)="{item}">
            <b-badge
              pill
              :variant="'light-primary'"
              class="text-capitalize"
            >
              {{ !item.binance_trade_id ? 'دستی' : 'اتوماتیک' }}
              {{ item.binance_trade_id }}
            </b-badge>
          </template>

          <template #cell(gain)="data">
                        <span dir="rtl">
                            {{ $toLocal(data.item.gain) }}
                        </span>
          </template>

          <template #cell(commission)="data">
                        <span dir="rtl">
                            {{ $toLocal(data.item.commission) }} {{ data.item.commission_asset }}
                        </span>
          </template>

          <template #cell(updatedAt)="data">
                        <span dir="ltr">
                            {{ $G2J(data.item.updated_at, 'faDateTime') }}
                        </span>
          </template>

          <!--                <template #cell(details)>-->
          <!--                    <feather-icon-->
          <!--                            icon="FileTextIcon"-->
          <!--                            size="20"-->
          <!--                            class="cursor-pointer"-->
          <!--                            v-tooltip="'مشاهده جزییات معامله'"-->
          <!--                            @click="isDetails=true"-->
          <!--                    />-->
          <!--                </template>-->

        </b-table>
      </div>

      <!-- pagination -->
      <div
        class="demo-spacing-0 d-flex justify-content-between m-1"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          align="left"
          @input="getData(currentPage,perPage)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
        <div>
          <label for="perpage">تعداد در صفحه</label>
          <v-select
            id="perpage"
            v-model="perPage"
            dir="rtl"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
            style="min-width: 85px"
            @input="getData(1,perPage)"
          />
        </div>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination,
  BCard,
  BButton,
  BTable,
  // BDropdown,
  // BDropdownItem,
  BBadge,
  BAvatar,
  BOverlay,
  BFormCheckbox
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';
// import HistoryDetails from "@/views/Deals/historyDetails";

export default {
  name: 'Deals',
  components: {
    // HistoryDetails,
    SearchAndFilter,
    BPagination,
    BCard,
    BButton,
    BTable,
    // BDropdown,
    // BDropdownItem,
    BBadge,
    BAvatar,
    vSelect,
    BOverlay,
    BFormCheckbox,
  },
  data: () => ({
    test: 0,
    pageLength: 3,
    dir: false,
    searchTerm: '',
    currentPage: 1,
    perPage: 10,
    rows: 20,
    sortBy: '',
    isSortDirDesc: '',
    userData: [],
    isActive: false,
    isDetails: false,
    BUY: true,
    perPageOptions: [5, 10, 20, 50, 100],
    items: [],
    columns: [
      {
        label: 'بازار',
        key: 'market',
        sortable: false,
        searchType: 'select',
        selectOptions: []
      },
      {
        label: 'کاربر',
        key: 'email',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'مقدار',
        key: 'amount',
        sortable: false,
        searchType: 'number'
      },
      {
        label: 'قیمت واحد (USDT)',
        key: 'price',
        sortable: false,
        searchType: 'number'
      },
      {
        label: 'ارزش کل(USDT)',
        key: 'total',
        sortable: false,
        searchType: 'number'
      },
      {
        label: 'قیمت واحد(تومان)',
        key: 'priceToman',
        sortable: false,
        searchType: 'number'
      },
      {
        label: 'ارزش کل(تومان)',
        key: 'totalToman',
        sortable: false,
        searchType: 'number'
      },
      {
        label: 'نوع',
        key: 'type',
        sortable: false,
      },
      {
        label: 'روش',
        key: 'method',
        sortable: false,
      },
      {
        label: 'کارمزد معامله',
        key: 'commission',
        sortable: false,
        // searchType: 'number'
      },
      {
        label: 'تاریخ معامله',
        key: 'updatedAt',
        sortable: false,
        searchType: 'date'
      },
      // {
      //     label: 'جزییات',
      //     key: 'details'
      // },
    ],
  }),
  computed: {
    statusVariant() {
      const a = {
        inactive: 'danger',
        active: 'success',
      }
      return e => a[e];
    },
    typeVariant() {
      const a = {
        SELL: 'danger',
        BUY: 'success',
      }
      return e => a[e]
    }
  },
  methods: {

    sort(e) {

      let sort = this.$toSnakeCase(e.sortBy)
      // let sort = e.sortBy
      let sorting = e.sortDesc ? 'DESC' : 'ASC'

      this.$router.push({
        query: {
          ...this.$route.query,
          orderBy: sort,
          sorting: sorting
        }
      })

      this.getData(1, this.perPage)

    },

    async getData(page, perPage) {
      this.BUY = this.BUY ? this.$route.query.type ? this.$route.query.type === 'BUY' : true : false
      const queryParams = {
        size: perPage,
        page: page,
        type: this.BUY ? 'BUY' : 'SELL',
        ...this.$route.query
      }
      this.state.loading = true
      const res = await this.$axios('/trades', {params: queryParams})
      this.state.loading = false
      this.items = res.data.data
      this.currentPage = res.data.meta.current_page
      this.rows = res.data.meta.total
    },
    async getMarket() {
      const res = await this.$axios('/markets?status=activated')
      this.state.loading = false
      let markets = res.data.data
      let market_array = markets.map(i => {
        return {
          label: i.coin.label + '-' + i.quote.label, value: i.name
        }
      })

      let i = this.columns.findIndex(i => i.key === 'market')
      this.columns[i].selectOptions = market_array
    }

  },
  mounted() {
    this.getData()
    this.getMarket()
  }
}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
